body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #333;
}

.season {
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.altcoin {
  background-color: #4CAF50;
  color: white;
}

.bitcoin {
  background-color: #FFA500;
  color: white;
}

.error {
  color: #D32F2F;
  font-weight: bold;
}